.input-group {
  position: relative;
  .form-control {
    padding-right: @input-height-base;
  }
}

.input-group-addon {
  position: absolute;
  top: 0;
  right: 0;
  width: @input-height-base;
  height: @input-height-base;
  line-height: @input-height-base;
  text-align: center;
  pointer-events: none;
}