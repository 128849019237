.message-404 {
  .center-block;
  max-width: 600px;
  text-align: center;
  text-shadow: 0 1px 3px fade(#000, 40%);
  color: #fff;
  h1 {
    color: inherit;
  }
  hr {
    .center-block;
    max-width: 30rem;
    border-top-color: fade(#fff, 40%);
  }
  .btn {
    width: 12rem;
  }
  .btn-default {
    text-shadow: none;
  }
  .btn-link {
    color: #fff;
    &:hover {
      border-color: #fff;
      border-radius: @border-radius-base;
    }
  }
}