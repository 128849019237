@box-padding-vertical: @padding-large-vertical;
@box-padding-horizontal: @padding-large-horizontal;
@box-padding: @box-padding-vertical @box-padding-horizontal;
@box-border: @hr-border;
@box-radius: @border-radius-base;

.box {
  margin-bottom: @line-height-computed;
  padding: @box-padding;
  border: 1px solid @box-border;
  background-color: #fff;
  border-radius: @box-radius;
  box-shadow: 0 1px 3px -1px @darker;

  // header,
  // footer {
  //   margin: -@box-padding-vertical -@box-padding-horizontal; 
  //   padding: @box-padding-vertical @box-padding-horizontal;
  // }

  // header {
  //   margin-bottom: @box-padding-vertical;
  //   padding-bottom: @box-padding-vertical;
  //   border-bottom: 1px solid @box-border;
  //   border-top-left-radius: (@box-radius - 1);
  //   border-top-right-radius: (@box-radius - 1);
  // }

  // footer {
  //   margin-top: @box-padding-vertical;
  //   padding-top: @box-padding-vertical;
  //   border-top: 1px solid @box-border;
  //   border-bottom-left-radius: (@box-radius - 1);
  //   border-bottom-right-radius: (@box-radius - 1);
  //   text-align: center;
  //   @media (min-width: @screen-sm) {
  //     button,
  //     input {
  //       min-width: 6em;
  //     }
  //   }
  // }
}

.box-small {
  .center-block;
  max-width: 300px;
}