.form-horizontal .control-label {
  text-align: left;
}

label[for]:hover {
  cursor: pointer;
}
input[type="radio"], 
input[type="checkbox"] {
  margin-top: 0;
  vertical-align: -.15em;
}

.large {
  font-size: larger;
} 

.trip-homepage {
  .box();
  .tighten-columns(@padding-base-horizontal);
  @media (min-width: @screen-sm) {
    margin-bottom: @line-height-computed;
  }

  h2 {
    margin-top: 0;
  }

  hr {
    border-top-style: dashed;
    margin: @padding-large-vertical 0;
  }

  label .text-muted {
    white-space: nowrap;
    font-weight: normal;
    font-style: italic;
    display: block;
    @media (max-width: 361px) {
      display: inline;
      &:before {
        content: '( ';
      }
      &:after {
        content: ' )';
      }
    }
  }

  .control-label {
    text-align: left;
  }

  .is_checked {
    // .button-variant(@text-color; #f7cf86; #e0b427);
    &:hover {
      cursor: default;
    }
    box-shadow: 0 1px 3px -1px @darker;
  }
  
}

.checked() {
  &, &:hover, &:focus {
    border-color: #e0b427;
    background: #f7cf86;
  }
}

.btn-trip-type {
  // width: 100%;
  input {
    display: none;
  }
  .btn {
    width: 100%;
    padding-left: @padding-small-horizontal;
    padding-right: @padding-small-horizontal;
    border-radius: @border-radius-base!important;
    &:hover,
    &:focus {
      border-color: @btn-default-border;
      // background-color: @gray-lighter;
    }
  }
  .is_checked {
    .checked();
  }
  small {
    display: block;
  }

  @media (max-width: @screen-xs-max) {
    .btn {
      display: block;
      float: none;
      width: auto;
      // border-radius: @border-radius-base!important;
      margin-bottom: @padding-large-vertical;
      white-space: normal;

      &:last-child {
        margin-bottom: 0;
      }
    }
    small {
      font-size: @font-size-base;
    }
  }
}

.bg-muted {
  background-color: @gray-lighter;
}

.steps-indicator {
  display: none;
}

.card-label {
  display: inline-block;
  padding: 1px;
  border-radius: @border-radius-base;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border-color: @btn-default-border;
  }

  &.is-selected {
    border-color: @input-border-focus;
  }

  img {
    .img-responsive;
    width: 100%;
  }
  input { display: none; }
  span { display: none; }
}

.modal-backdrop {
  opacity: @modal-backdrop-opacity;
}

.glyphicon {
  &:extend(.icon);
}
.glyphicon-chevron-left {
  &:extend(.icon-arrow-back all);
}
.glyphicon-chevron-right {
  &:extend(.icon-arrow-forward all);
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  max-width: none;
  width: 100%;
}

.dropdown-destinations {
  .clearfix();
  width: @container-sm;
  > li {
    .make-sm-column(4);
  }
}

.homepage-carousel {
  margin-bottom: @line-height-computed;
  padding: @box-padding;
  border: 1px solid @box-border;
  background-color: #fff;
  border-radius: @box-radius;
  box-shadow: 0 1px 3px -1px @darker;
}
