.navbar-default {
  border-width: 0 0 1px 0;
  box-shadow: 0 1px 3px @darker;
}

.navbar-brand {
  height: auto;
  @media (max-width: 440px) {
    img {
      height: 36px;
      width: auto;
    }
  }
}

.navbar-toggle {
  margin-top: 15px;
  .btn-text {
    @media (max-width: 440px) {
      display: none;
      padding: 6px;
    }
  }
}

.navbar-collapse hr {
  clear: both;
  border-top-color: lighten(@navbar-default-border, 10%);
  margin: @navbar-padding-vertical -@navbar-padding-horizontal 0;
  @media (max-width: @grid-float-breakpoint-max) {
    margin-bottom: @navbar-padding-vertical;
  }
}

.navbar-nav {
  float: none;
  clear: both;
  margin-left: -@navbar-padding-horizontal;
  margin-right: -@navbar-padding-horizontal;
  @media (min-width: @grid-float-breakpoint) {
    & > li:last-child {
      float: right;
    }
  }
}

.navbar-nav .open .dropdown-menu {
  @media (max-width: @grid-float-breakpoint-max) {
    background-color: @gray-lighter;
  }
}

.navbar-contact {
  overflow: auto;

  .icon {
    vertical-align: middle;
  }

  @media (max-width: @grid-float-breakpoint-max) {
    .icon {
      float: left;
      margin-top: (@line-height-computed / 2);
      margin-bottom: (@line-height-computed / 2);
      margin-right: @padding-base-horizontal;
      font-size: (@line-height-computed * 2);
      line-height: 1;
    }
  }
}

.navbar-contact-numbers {
  /* .navbar-contact-numbers = ul */
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;

  &, li {
    display: inline;
  }

  @media (max-width: @grid-float-breakpoint-max) {
    float: left;
    &, li {
      display: block;
    }
  }
}