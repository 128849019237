.flight-listing {
  .list-unstyled();
  border-top: 1px solid @hr-border;
  border-bottom: 1px solid @hr-border;
  & > li {
    border-top: 1px dashed @hr-border;
    margin-top: -1px;
  }
  label {
    .clearfix();
    position: relative;
    display: block;
    margin: @padding-large-vertical 0;
    padding: @padding-base-vertical @padding-base-horizontal;

    font-weight: normal;
    font-family: monospace;
    
    border: 1px solid transparent;
    border-radius: @border-radius-base;

    .icon {
      visibility: hidden;
    }

    &:hover,
    &:focus {
      border-color: @btn-default-border;
      // .icon {
      //   visibility: visible;
      // }
    }

    &.is_checked {
      .checked();
      padding-left: @line-height-computed;

      .flight-select {
        color: @component-active-color;
        background-color: @component-active-bg;
      }      
      .icon {
        visibility: visible;
      }
    }
  }

  input { display: none; }

  .flight-date,
  .flight-id {
    float: left;
    width: 120px;
    margin-right: @padding-base-horizontal;
    clear: left;
  }

  .flight-select {
    @w: @line-height-computed;
    @h: @w;
    position: absolute;
    top: 50%;
    left: 0;
    width: @w;
    height: @h;
    margin-left: (-@w / 2);
    margin-top: (-@h / 2);

    line-height: @line-height-computed;
    font-size: @font-size-small;
    text-align: center;
    vertical-align: 2px;

    border: 1px solid transparent;
    border-radius: @border-radius-base;
  }
  .icon {
    vertical-align: -1px;
  }

  .flight-price {
    float: right;
    color: @brand-danger;
  }
}