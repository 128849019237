.btn-to-top,
.btn-back {
  &:extend(.btn all);
  &:extend(.btn-primary all);
  padding: .4em .2em;
  position: fixed;
  width: 2.4em;
  text-align: center;
  z-index: (@zindex-navbar - 1);
  box-shadow: 0 .1em .3em fade(#000, 40%);
  opacity: .8;
  transition: opacity 100ms,
              bottom 100ms;
  &:hover,
  &:focus {
    opacity: 1;
  }
}

.btn-to-top {
  right: .5em;
  bottom: .5em;
  &:hover,
  &:focus {
    bottom: 1em;
  }
  @media (min-width: @screen-lg) {
    position: fixed;
    left: 50%;
    margin-left: (@container-lg / 2 + @padding-base-horizontal); 
  }
}

.btn-back {
  left: .5em;
  bottom: .5em;
  &:hover,
  &:focus {
    left: -1em;
  }
  @media (min-width: @screen-lg) {
    position: absolute;
    top: 0;
    bottom: auto;
    left: -3em;
  }
}

.btn-to-top-container,
.btn-back-container {
  position: relative;
}