.slick-slide {
  img {
    margin: 0 auto;
  }
}

.slick-prev,
.slick-next {
  top: 100%;
  z-index: 2;
  margin-top: 12px;
  transform: translate(0, 0);

  &:before {
    color: #444;
  }
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}