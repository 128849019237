.wizard {
  counter-reset: wizard;
}

.wizard-step {
  // &:extend(.box all);
  .tighten-columns(@padding-base-horizontal);

  header,
  footer {
    color: #fff;
    h1, h2, h3, h4 {
      color: inherit;
    }
  }

  footer {
    .btn-link {
      text-shadow: 0 1px 3px fade(#000, 40%);
      color: #fff;
      font-weight: bold;
    }
  }

  h3 {
    margin-top: @padding-base-vertical;
    small {
      display: block;
      color: inherit;
      font-weight: bold;
    }
  }
}

.pick-flight {
  .flight-departure,
  .flight-return {
    &:extend(.box all);
  }
  .flight-listing {
    margin-left: -@box-padding-horizontal;
    margin-right: -@box-padding-horizontal;
    padding-left: @box-padding-horizontal;
    padding-right: @box-padding-horizontal;
  }
}

.contact {
  .wizard-body {
    &:extend(.box all);
  }
}

.payment {
  .wizard-body {
    &:extend(.box all);
  }
}

.passengers {
  .passenger {
    &:extend(.box all);
  }
}

.finish {
  .icon {
    .square(60px);
    box-sizing: content-box;
    display: block;
    margin: @line-height-computed auto;
    border: 2px solid;
    border-radius: 50%;
    line-height: 60px;
    font-size: x-large;
    text-align: center;
  }
  .icon-check {
    color: @brand-success;
    border-color: @brand-success;
  }
  .icon-warning {
    color: @brand-danger;
    border-color: @brand-danger;
  }
}

.step-title {
  margin-top: 0;
  text-shadow: 0 1px 3px fade(#000, 20%);

  &:before {
    .square(1.5em);
    box-sizing: content-box;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: baseline;
    margin-right: @padding-large-horizontal;
    border: 2px solid;
    border-radius: 50%;
    text-align: center;
    color: inherit; 
    counter-increment: wizard;
    content: counter(wizard); //  ". ";
  }
}

.step-error-message {
  @error-padding: (@padding-large-horizontal + 4px);
  margin: 0 -@error-padding @padding-large-vertical;
  padding: @padding-large-vertical @error-padding;
  border-radius: @box-radius;
  background-color: @brand-danger;
  box-shadow: 0 .3em .3em -.2em fade(#000, 20%);
  color: #fff;

  &:before {
    .icon();
    display: inline-block;
    margin-right: @padding-small-horizontal;
    content: @icon-warning;
    vertical-align: middle;
  }
}

.wizard {
  .passenger {
    padding-bottom: @box-padding-vertical;
    margin-bottom: @box-padding-vertical;
    border-bottom: 1px solid @box-border;
    &:last-child {
      border-bottom: 0;
    }
  }
}