html,
body {
  height: 100%;
}

body {
  // background-color: @gray-lighter;
  background-image: url('../images/bg-plane-sky.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.viewport {
  position: relative;
  overflow: auto;
  min-height: 100%;
}

main {
  margin: (@padding-large-vertical * 2) 0;
}

.footer {
  position: relative;
  z-index: (@zindex-navbar - 2);
  // border-top: 1px solid @navbar-default-border;
  box-shadow: 0 -1px 3px @darker; 
  .disclaimer {
    padding: @padding-large-vertical 0;
    font-size: @font-size-small;
    background: #fff;
  }
}

.container {
  @media (max-width: @screen-md-max) {
    width: auto;
  }
}

.photo-credits {
  position: absolute;
  left: (@grid-gutter-width / 2);
  bottom: @padding-base-vertical;
  color: #fff;
  text-shadow: 0 1px 1px @darker;
  a {
    color: inherit;
    font-weight: bolder;
  }
}
