@font-face {
  font-family: 'travel';
  src:url('@{icon-font-path}travel.eot?880kde');
  src:url('@{icon-font-path}travel.eot?#iefix880kde') format('embedded-opentype'),
    url('@{icon-font-path}travel.woff?880kde') format('woff'),
    url('@{icon-font-path}travel.ttf?880kde') format('truetype'),
    url('@{icon-font-path}travel.svg?880kde#travel') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font-family: 'travel';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 140%;
  line-height: .8;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
  text-rendering: auto; // optimizelegibility throws things off #1094
}

@icon-delete: "\e600";
@icon-check: "\e60c";
@icon-calendar: "\e601";
@icon-search: "\e609";
@icon-one-way: "\e602";
@icon-warning: "\e603";
@icon-loop: "\e607";
@icon-list-add: "\e60a";
@icon-two-way: "\e604";
@icon-add: "\e611";
@icon-clear: "\e612";
@icon-remove: "\e613";
@icon-time: "\e614";
@icon-bus: "\e61a";
@icon-car: "\e61b";
@icon-train: "\e60d";
@icon-plane: "\e60f";
@icon-hotel: "\e61c";
@icon-place: "\e610";
@icon-arrow-back: "\e61f";
@icon-drop-down: "\e605";
@icon-drop-up: "\e606";
@icon-arrow-forward: "\e620";
@icon-expand-less: "\e615";
@icon-expand-more: "\e616";
@icon-menu: "\e608";
@icon-more-horiz: "\e617";
@icon-more-vert: "\e60b";
@icon-refresh: "\e618";
@icon-unfold-less: "\e622";
@icon-unfold-more: "\e623";
@icon-forbidden: "\e619";
@icon-phone-ring: "\e624";
@icon-globe: "\e60e";

.icon-delete:before {
  content: @icon-delete;
}
.icon-check:before {
  content: @icon-check;
}
.icon-calendar:before {
  content: @icon-calendar;
}
.icon-search:before {
  content: @icon-search;
}
.icon-one-way:before {
  content: @icon-one-way;
}
.icon-warning:before {
  content: @icon-warning;
}
.icon-loop:before {
  content: @icon-loop;
}
.icon-list-add:before {
  content: @icon-list-add;
}
.icon-two-way:before {
  content: @icon-two-way;
}
.icon-add:before {
  content: @icon-add;
}
.icon-clear:before {
  content: @icon-clear;
}
.icon-remove:before {
  content: @icon-remove;
}
.icon-time:before {
  content: @icon-time;
}
.icon-bus:before {
  content: @icon-bus;
}
.icon-car:before {
  content: @icon-car;
}
.icon-train:before {
  content: @icon-train;
}
.icon-plane:before {
  content: @icon-plane;
}
.icon-hotel:before {
  content: @icon-hotel;
}
.icon-place:before {
  content: @icon-place;
}
.icon-arrow-back:before {
  content: @icon-arrow-back;
}
.icon-drop-down:before {
  content: @icon-drop-down;
}
.icon-drop-up:before {
  content: @icon-drop-up;
}
.icon-arrow-forward:before {
  content: @icon-arrow-forward;
}
.icon-expand-less:before {
  content: @icon-expand-less;
}
.icon-expand-more:before {
  content: @icon-expand-more;
}
.icon-menu:before {
  content: @icon-menu;
}
.icon-more-horiz:before {
  content: @icon-more-horiz;
}
.icon-more-vert:before {
  content: @icon-more-vert;
}
.icon-refresh:before {
  content: @icon-refresh;
}
.icon-unfold-less:before {
  content: @icon-unfold-less;
}
.icon-unfold-more:before {
  content: @icon-unfold-more;
}
.icon-forbidden:before {
  content: @icon-forbidden;
}
.icon-phone-ring:before {
  content: @icon-phone-ring;
}
.icon-globe:before {
  content: @icon-globe;
}
