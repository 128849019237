@loader-size: 1em;
@loader-swing: 3em;
@loader-bg: fade(@brand-primary, 60%);
@loader-z: (@zindex-navbar - 1); // 1000;
body.is-loading {
  .loader-container {
    display: block;
  }
}
.loader-container {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @loader-z;
  background: @loader-bg;
  .loader {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.loader {
  margin: @loader-size auto (@loader-size * 2);
  position: relative;
  transform: translateZ(0);
  color: #fff;
  font-weight: bold;
  font-size: @font-size-small;
  text-transform: uppercase;
  text-align: center;
  line-height: 2em;
  letter-spacing: .05em;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: @loader-size;
    height: @loader-size;
    margin-top: -@loader-size;
    background: transparent;
    animation-fill-mode: both;
    animation: loader 1.8s infinite ease-in-out;
  }
}
@keyframes loader {
  0%,
  100% {
    box-shadow: @loader-swing @loader-size 0 0 #fff;
  }
  50% {
    box-shadow: -@loader-swing @loader-size 0 0 #fff;
  }
}
