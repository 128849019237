@box-padding-vertical: @padding-large-vertical;
@box-padding-horizontal: @padding-large-horizontal;
@box-padding: @box-padding-vertical @box-padding-horizontal;
@box-border: @hr-border;

.box() {
  margin-bottom: @line-height-computed;
  padding: @box-padding;
  border: 1px solid @box-border;
  background-color: #fff;
  border-radius: @border-radius-base;
  box-shadow: 0 1px 3px -1px @darker;
}

.tighten-columns(@gutter) {
  .row,
  .form-horizontal .form-group {
    margin-left: floor((-@gutter / 2));
    margin-right: floor((-@gutter / 2));
  }

  [class*="col-"] {
    padding-left: floor((@gutter / 2));
    padding-right: floor((@gutter / 2));
    @media (max-width: 360px) {
      display: block;
      float: none;
      width: auto;
    }
  }
}